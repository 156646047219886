<template>
    <div>
        <recess-divider variant="large" show-line />

        <div v-if="order" class="row">
            <div class="col-12 my-3">
                <h2 class="qa-billing-table-title">{{ PROVIDER.Order.OrderDetails.ProviderBillingTable.TableTitle }}</h2>
            </div>

            <div class="col-4">
                <div>
                    <h5>{{ PROVIDER.Order.OrderDetails.ProviderBillingTable.Name }}</h5>
                    <p class="qa-billing-order-name">{{ order.name || '-' }}</p>
                </div>

                <div>
                    <h5>{{ PROVIDER.Order.OrderDetails.ProviderBillingTable.OrderNumber }}</h5>
                    <p class="qa-billing-order-number">{{ order.orderNumber || '-' }}</p>
                </div>

                <div>
                    <h5>{{ PROVIDER.Order.OrderDetails.ProviderBillingTable.Total }}</h5>
                    <p class="qa-billing-order-total">{{ Number(order.total) | currency }} incl BTW.</p>
                </div>

                <div>
                    <h5>{{ PROVIDER.Order.OrderDetails.ProviderBillingTable.Subsidy }}</h5>
                    <p class="qa-billing-order-subsidy">{{ order.subsidyDisplayValue || '-' }}</p>
                </div>

                <div>
                    <h5>{{ PROVIDER.Order.OrderDetails.ProviderBillingTable.ProviderPurchaseOrderReference }}</h5>
                    <p class="qa-billing-order-purchase-order-ref">
                        {{ order.providerPurchaseOrderReference || '-' }}
                    </p>
                </div>
            </div>

            <div class="col-4">
                <div v-if="invoicedByOplz || invoicedByOpleidingDirect" class="mb-4">
                    <h5>{{ PROVIDER.Order.OrderDetails.ProviderBillingTable.FirstParagraph.PartOne }}</h5>
                    <p>{{ PROVIDER.Order.OrderDetails.ProviderBillingTable.FirstParagraph.PartTwo }}</p>
                </div>

                <div v-if="invoicedByOplz || invoicedByOpleidingDirect || invoicedByNcoi">
                    <h5>{{ PROVIDER.Order.OrderDetails.ProviderBillingTable.BillingAddress.Title }}</h5>

                    <p v-if="invoicedByOplz" class="qa-billing-order-account-name">
                        {{ order.billTo.company || '' }}
                        <span class="d-block">{{ PROVIDER.Order.OrderDetails.ProviderBillingTable.BillingAddress.PartOne.Oplz }}</span>
                        <span class="d-block">{{ PROVIDER.Order.OrderDetails.ProviderBillingTable.BillingAddress.PartTwo }}</span>
                        <span class="d-block">{{ PROVIDER.Order.OrderDetails.ProviderBillingTable.BillingAddress.PartThree }}</span>
                    </p>

                    <p v-if="invoicedByOpleidingDirect" class="qa-billing-order-account-name">
                        {{ PROVIDER.Order.OrderDetails.ProviderBillingTable.BillingAddress.PartOne.Others }}
                        <span class="d-block">{{ PROVIDER.Order.OrderDetails.ProviderBillingTable.BillingAddress.PartTwo }}</span>
                        <span class="d-block">{{ PROVIDER.Order.OrderDetails.ProviderBillingTable.BillingAddress.PartThree }}</span>
                    </p>

                    <p v-if="invoicedByNcoi" class="qa-billing-order-account-name">
                        {{ PROVIDER.Order.OrderDetails.ProviderBillingTable.BillingAddress.PartOne.Others }}
                        <span class="d-block">{{ PROVIDER.Order.OrderDetails.ProviderBillingTable.BillingAddress.PartTwo }}</span>
                        <span class="d-block">{{ PROVIDER.Order.OrderDetails.ProviderBillingTable.BillingAddress.PartThree }}</span>
                    </p>
                </div>
            </div>

            <div class="col-4">
                <div v-if="order.invoicedBy">
                    <h5>{{ PROVIDER.Order.OrderDetails.ProviderBillingTable.InvoicedBy }}</h5>
                    <p class="qa-billing-order-invoiced-by">
                        {{ order.invoicedByDisplayValue || '-' }}
                    </p>
                </div>

                <slot name="edit-invoice-on-behalf-of"></slot>
            </div>
        </div>
    </div>
</template>

<script>
import { PROVIDER } from '@/constants/EdumsProviderConstants.json'

export default {
    name: 'ProviderBillingTableComponent',
    props: {
        order: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            PROVIDER
        }
    },
    computed: {
        invoicedByOplz() {
            return this.order.invoicedBy === 'OPLZ'
        },
        invoicedByOpleidingDirect() {
            return this.order.invoicedBy === 'OpleidingDirect'
        },
        invoicedByNcoi() {
            return this.order.invoicedBy === 'NCOI'
        }
    }
}
</script>
